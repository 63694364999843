body::-webkit-scrollbar {
  width: 10px;
  background: #8853a11a;
  box-shadow: none;
  z-index: 9999;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(109, 65, 161);
  height: 10px;
  border-radius: 100px;
  box-shadow: none;
}

body::-webkit-scrollbar-track {
  display: none;
}

body::-webkit-scrollbar-track-piece {
  box-shadow: none;
  background: #fafafa;
}

#ws-content-wrapper {
  margin-top: 90px;
}

@media only screen and (max-device-width: 767px) {
  #ws-content-wrapper {
    margin-top: 72px;
  }
}
